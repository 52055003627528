<template>
  <!-- <div style="font-size: .729px">
    
    <el-carousel
      :height="swiperHeight + 'px'"
      :interval="7300"
      :autoplay="true"
    >
      <el-carousel-item v-for="item in swiper" :key="item" ref="item">
        <img class="swiper-img" :src="item" alt="" ref="image" />
        
      </el-carousel-item>
    </el-carousel>
  </div> -->
  <div class="swiper">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="item in swiper" :key="item.id">
        <img
          class="swiper-img"
          :src="$store.state.isPc ? item.src : item.msrc"
          alt=""
          ref="image"
          fit="cover"
        />
        <div class="modal flex">
          <div class="box">
            <h4 class="h4" v-html="item.slogan"></h4>
            <p v-html="item.title"></p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="swiper-button-prev"></div> -->
    <!--左箭头。如果放置在swiper外面，需要自定义样式。-->
    <!-- <div class="swiper-button-next"></div> -->
    <!--右箭头。如果放置在swiper外面，需要自定义样式。-->

    <!-- swiper轮播图圆点 -->
    <div class="swiper-pagination-box">
      <div class="btm flex">
        <div class="swiper-button flex">
          <div class="custom-swiper-button-prev">
            <el-image :src="swiperButton" fit="fill"></el-image>
          </div>
          <div class="custom-swiper-button-next">
            <el-image :src="swiperButton" fit="fill"></el-image>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import Swiper from "swiper";
  import "swiper/css/swiper.min.css";
  // import Vue from "vue";

  export default {
    data() {
      return {
        swiper: [
          {
            id: 1,
            src: require("@/assets/home/swiper/s1.png"),
            msrc: require("@/assets/home/swiper/s11.png"),
            slogan: "建设绿色出行新格局<br />感谢每一次“誉”见",
            title:
              "积极构建低碳交通体系，布局全球充电网络，让绿色出行成为一种生活方式。 ",
          },
          {
            id: 2,
            src: require("@/assets/home/swiper/s2.png"),
            msrc: require("@/assets/home/swiper/s22.png"),
            slogan: "推动”双碳”目标<br />构建人类命运共同体",
            title: "将清洁能源全面应用到千家万户 ",
          },
          {
            id: 3,
            src: require("@/assets/home/swiper/s3.png"),
            msrc: require("@/assets/home/swiper/s33.png"),
            slogan: "储存太阳能量<br />点亮万家灯火",
            title: "通过光伏+储能，在不改变人们用电习惯的情况下实现绿电自由。",
          },
        ],
        swiperHeight: null,
        style: "",
        url: "",
        swiperButton: require("@/assets/home/img/icon_arrow_r.png"),
      };
    },

    mounted() {
      this.$nextTick(() => {
        // 一旦完成界面更新, 立即执行回调
        new Swiper(".swiper", {
          autoplay: {
            delay: 5000,
          },
          // autoplay: false,
          navigation: {
            nextEl: ".custom-swiper-button-next",
            prevEl: ".custom-swiper-button-prev",
          },
          loop: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        });
      });
    },
    computed: {
      toBase() {
        return function (item) {
          console.log(item);
          var dataURL = null;
          //一定要设置为let，不然图片不显示

          console.log("dataURL", dataURL);
          // return image.onload;
        };
      },
    },
  };
</script>

<style lang="less" scoped>
  .swiper {
    position: relative;
    overflow: hidden;
    .swiper-slide {
      overflow: hidden;
      position: relative;
      .modal {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        align-items: center;
        .box {
          width: 100%;
          max-width: 1200px;
          text-align: left;
          margin: 0 auto;
          cursor: pointer;
          h4,
          p {
            color: #fff;
            opacity: 0;
            transform: translateX(120px);
            transition: ease all 0.5s;
            transition-delay: 0.2s;
          }
          h4 {
            font-family: AlibabaPuHuiTi45;
            font-size: 48px;
            height: max-content;
            line-height: initial;
            margin-top: -45px;
          }
          p {
            font-size: 18px;
            line-height: 30px;
          }
        }
      }
      // .des {
      //   top: 0;
      //   position: absolute;
      //   opacity: 0;
      //   transform: translateY(10%);
      // }
    }
    .swiper-img {
      width: 100%;
      // height: 100%;
      height: auto;
    }
    .swiper-slide-active {
      h4,
      p {
        opacity: 1 !important;
        transform: translateX(0) !important;
      }
      // .swiper-bg {
      //   width: 100%;
      //   height: 100%;
      //   position: absolute;
      //   top: 0;
      //   background: #fff;
      //   transform: translateZ(0);
      //   -webkit-transform: translateZ(0);
      //   animation: left 0.2s;
      //   animation-delay: 0.4s;
      //   animation-fill-mode: both;
      //   transform: translateX(-50%);
      //   transition: transform 0.4s ease-in 6.9s;
      // }
      // .des-box {
      //   opacity: 0;
      //   transition: opacity 0.4s ease-in 6.9s;
      // }
      // .des {
      //   width: 50%;
      //   height: 100%;
      //   background: transparent;
      //   position: absolute;
      //   left: 50%;
      //   top: 0;
      //   opacity: 0;
      //   color: #000;
      //   text-align: left;
      //   animation: opacityChange 0.4s;
      //   animation-delay: 0.6s;
      //   animation-fill-mode: both;
      //   overflow: hidden;
      //   > img {
      //     margin-top: 22.7rem;
      //     margin-bottom: 3.6rem;
      //     margin-left: 12.7rem;
      //   }
      //   > h4 {
      //     font-size: 6rem;
      //     font-weight: 600;
      //     margin-bottom: 2.8rem;
      //     margin-left: 12.7rem;
      //     margin-top: 0;
      //   }
      //   > h5 {
      //     font-size: 2.8rem;
      //     font-weight: normal;
      //     margin-bottom: 10.5rem;
      //     margin-left: 12.7rem;
      //   }
      //   .el-button {
      //     background: #ec660d;
      //     color: #fff;
      //     width: 20rem;
      //     // height: 4.9997rem;
      //     border: 0;
      //     border-radius: 0.4rem;
      //     margin-left: 12.7rem;
      //   }
      // }
    }
    .swiper-pagination-box {
      position: absolute;
      bottom: 4.8rem;
      width: 100%;
      left: 0;
      .btm {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        justify-content: space-between;
        .swiper-button {
          z-index: 1;
          > div {
            width: 54px;
            height: auto;
            cursor: pointer;
          }
          .custom-swiper-button-prev {
            transform: rotate(180deg);
            margin-right: 24px;
          }
        }

        /deep/ .swiper-pagination {
          position: relative;
          .swiper-pagination-bullet {
            position: relative;
            width: 10px;
            height: 10px;
            background: #fff;
            border-radius: 0;
            margin-left: 12px;
            transition: ease all 0.5s;
            &.swiper-pagination-bullet-active {
              width: 30px;
              background: #ed670d;
            }
          }
          // .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
          //   content: "";
          //   width: 0;
          //   height: 0.4rem;
          //   position: absolute;
          //   left: 0;
          //   top: 0;
          //   background: #ec660d;
          //   -webkit-animation: bannerProgress 7.5s ease-in-out both;
          //   animation: bannerProgress 7.5s ease-in-out both;
          // }
        }
      }
    }
  }

  @media screen and(max-width: 1024px) {
    .swiper .swiper-slide .modal .box {
      padding: 0 24rem;
      h4 {
        font-size: 22rem;
      }
      p {
        font-size: 11rem;
        line-height: initial;
        margin: 23rem 0;
      }
    }
    .container {
      .swiper {
        height: 654rem !important;
        .swiper-pagination-box {
          width: calc(100% - 48rem);
          box-sizing: border-box;
          bottom: 133rem;
          border-bottom: 1px solid #ffffff;
          padding-bottom: 24rem;
          margin: 0 24rem;
          z-index: 99;
        }
      }
    }
  }
</style>
