





































































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Header extends Vue {
  logo: string = require("@/assets/logo-white.png");
  hoverLogo: string = require("@/assets/logo-gray.png");
  menu: string = require("@/assets/common/img/menu.png");
  ishover = false;
  isfix = false;
  prevScroll = 0;
  drawer = false;

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  }
  handleScroll() {
    let scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;
    if (scrollTop > 56 && scrollTop < this.prevScroll) {
      this.isfix = true;
      // this.logo = this.hoverLogo;
    } else {
      this.isfix = false;
      // this.logo = require("@/assets/logo-white.png");
    }
    this.prevScroll = scrollTop;
  }

  Back() {
    this.$router.push({
      path: "/index",
    });
  }

  showBG() {
    this.ishover = true;
  }

  hideBG() {
    this.ishover = false;
  }

  toClean() {
    this.$router.push({
      path: "/clean",
    });
  }

  toCharge() {
    this.$router.push({
      path: "/charge",
    });
  }

  navigate(path: string) {
    // return false;
    this.$router.push({
      path,
    });
  }
}
