








































































































import { Component, Vue } from "vue-property-decorator";
export default class Footer extends Vue {
  wchat = require("@/assets/common/img/vchat.png");
  weibo = require("@/assets/common/img/weibo.png");

  pop() {
    this.$message({
      message: "敬请期待",
    });
  }
}
