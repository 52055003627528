








































































































































































































import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/common/Header.vue"; // @ is an alias to /src
import Footer from "@/components/common/Footer.vue"; // @ is an alias to /src
import Mainswiper from "@/components/Home/Mainswiper.vue";
@Component({
  components: {
    Mainswiper,
    Footer,
    Header,
  },
})
export default class Home extends Vue {
  bg: string = require("@/assets/power/img/bg.png");
  logo: string = require("@/assets/logo-white.png");
  hoverLogo: string = require("@/assets/logo-gray.png");
  gsjj: string = require("@/assets/home/img/gsjj.jpg");
  yunying: string = require("@/assets/home/img/yunying.png");
  qywh: string = require("@/assets/home/img/qywh.jpg");
  arraw: string = require("@/assets/home/img/icon_arrow_r.png");
  arrawblack: string = require("@/assets/home/img/arrawblack.png");
  arrawwhite: string = require("@/assets/home/img/icon_arrow_r.png");
  rc: string = require("@/assets/home/img/rc.jpg");
  gy: string = require("@/assets/home/img/gy.png");
  lt: string = require("@/assets/home/img/lt.jpg");
  part1: string = require("@/assets/home/img/guangfu.jpg");
  // ishover = false;
  // isfix = false;
  // prevScroll = 0;
  showslogan = -1;
  toggleShow = 1; // 智能硬件等模块显示影藏
  wenan1 = [
    {
      title: "光伏组件",
      subtitle:
        "开拓光伏多元化应用场景<br />积极布局，打造， 建设一体化新能源生态圈",
      image: require("@/assets/home/img/guangfu.jpg"),
    },
    {
      title: "充电设备",
      subtitle:
        "为绿色交通浇筑坚实基础<br />积极建设充电基础设施，网格化充电布局",
      image: require("@/assets/home/img/chongdian.jpg"),
    },
    {
      title: "储能产品",
      subtitle:
        "新能源电网的关键支撑<br />积极推进储能系统应用，提高电网可靠性、安全性与稳定性",
      image: require("@/assets/home/img/chuneng.jpg"),
    },
  ];
  wenan2 = [
    {
      title: "誉照生态网络",
      subtitle: "链接能源站点，布局绿色未来<br />一体化信息终端解决方案",
      image: require("@/assets/home/img/chongdianfuwu.jpg"),
    },
    {
      title: "Oasis碳管理综合平台",
      subtitle:
        "通过数据化、可视化、智能化的碳数据分析<br />帮助全产业实现精细化碳管理，推进全产业碳中和转型",
      image: require("@/assets/home/img/tan.jpg"),
    },
    {
      title: "综合能源管理系统",
      subtitle:
        "能源监测、智能调度、分测于一体<br />安全、稳定、高效的提升能源管理维度",
      image: require("@/assets/home/img/saas.jpg"),
    },
    {
      title: "YuOS能源物联网",
      subtitle:
        "能源IOT硬件连接管理系统，全场景应用、监测与能源数字管理，铸造智慧能源数字生态新格局",
      image: require("@/assets/home/img/yunos.jpg"),
    },
  ];
  wenan3 = [
    {
      title: "于个人",
      subtitle:
        "低碳生活新维度，时尚生活新理念<br />塑造出行方式多样化，零碳手段精细化，低碳生活常态化的可持续性未来",
      image: require("@/assets/home/img/geren.jpg"),
    },
    {
      title: "于企业",
      subtitle:
        "节能降碳，创造可持续的低碳经济效益<br />提供可再生能源技术支持，促进生产与运营方式的转变",
      image: require("@/assets/home/img/qiye.jpg"),
    },
    {
      title: "于公用事业机构",
      subtitle:
        "为政府机关实现自身办公的低碳化，打造节能机构<br />常态化建设低碳社会经济，全面推进各产业共筑绿碳未来",
      image: require("@/assets/home/img/gongyongshiye.jpg"),
    },
    {
      title: "于社会",
      subtitle:
        "创新低碳经济模式与低碳生活方式，<br />全面改善社会生活环境提高生活质量，促进新经济发展",
      image: require("@/assets/home/img/shehui.jpg"),
    },
  ];

  partItem = [
    {
      id: 1,
      title: "智能硬件",
      subtitle: "“互联网”与“人工智能”的重要载体",
      image: require("@/assets/home/img/guangfu.jpg"),
    },
    {
      id: 1,
      title: "智慧软件",
      subtitle: "链接云端和智能硬件终端的数字通道",
      image: require("@/assets/home/img/chongdian.jpg"),
    },
    {
      id: 1,
      title: "智心服务",
      subtitle: "极致的用户体验，尽享零碳生活",
      image: require("@/assets/home/img/chuneng.jpg"),
    },
  ];

  cards = [
    {
      id: 1,
      title: "社会责任",
      subtitle:
        "实现能源与环境融合发展，从独立个体到社会环境，<br />为社会创造绿色经济效益。",
      image: require("@/assets/home/img/gy.png"),
      link: "/home/social",
    },
    {
      id: 2,
      title: "零碳与未来",
      subtitle:
        "驱动低碳生活，全面实现社会经济绿色复苏<br />共同构筑可持续发展的智能新世界。",
      image: require("@/assets/home/img/lt.jpg"),
    },
  ];

  // mounted() {
  //   window.addEventListener("scroll", this.handleScroll);
  // }
  // handleScroll() {
  //   let scrollTop =
  //     window.pageYOffset ||
  //     document.documentElement.scrollTop ||
  //     document.body.scrollTop;
  //   if (scrollTop > 56 && scrollTop < this.prevScroll) {
  //     // 超出头部高度之后回滚
  //     this.isfix = true;
  //     // this.logo = this.hoverLogo;
  //   } else {
  //     this.isfix = false;
  //     // this.logo = require("@/assets/logo-white.png");
  //   }
  //   this.prevScroll = scrollTop;
  // }
  showSlogan(i: any) {
    this.showslogan = i;
  }
  hideSlogan(i: any) {
    this.showslogan = -1;
  }
  toggle(type: number) {
    this.toggleShow = type;
    console.log(1, this.toggleShow);
  }
  navigate(url: any) {
    this.$router.push({
      path: url,
    });
  }
  // pop() {}
}
