import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Elementui from "element-ui";
// import VueAwesomeSwiper from 'vue-awesome-swiper'
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/font/font.css";
import "@/assets/css/custom.less"

import VueLazyLoad from 'vue-lazyload'

Vue.config.productionTip = false;
Vue.use(VueLazyLoad)

Vue.use(Elementui);
// Vue.prototype.$toBase64 = (item: any) => {
//   const image = new Image();
//   //解决跨域问题
//   image.setAttribute("crossOrigin", "anonymous");
//   const imageUrl = item;
//   image.src = imageUrl;
//   //image.onload为异步加载
//   return image.onload = () => {
//     const canvas = document.createElement("canvas");
//     canvas.width = image.width;
//     canvas.height = image.height;
//     const context: any = canvas.getContext("2d");
//     context.drawImage(image, 0, 0, image.width, image.height);
//     const quality = 0.8;
//     //这里的dataurl就是base64类型
//     return canvas.toDataURL("image/jpeg", quality); //使用toDataUrl将图片转换成jpeg的格式,不要把图片压缩成png，因为压缩成png后base64的字符串可能比不转换前的长！

//     // console.log("dataURL", dataURL);
//     // return dataURL;
//   };
//   // console.log('img', image);

// };
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
